@tailwind base;

@layer base {
    html {
        @apply h-full;
        @apply overflow-hidden;    
    }
    #root {
        @apply h-full;
        @apply w-full;
    }
    body {
        @apply h-full;
        @apply overflow-hidden;
        @apply m-0;
        @apply bg-trc-blue;
        @apply text-gray-700;
    }
    input[type="text"],input[type="password"] {
        @apply w-full;
        @apply px-4;
        @apply py-2;
        @apply text-lg;
        @apply font-semibold;
        @apply border-2;
        @apply rounded-md;
        @apply border-trc-blue;
    }
}

@tailwind components;
@tailwind utilities;